@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body { overflow: hidden; }
#app { height: 100vh; display: flex; flex-flow: column nowrap; scroll-padding-top: 72px; overflow-y: auto; scroll-behavior: smooth; -ms-scroll-snap-type: y proximity; scroll-snap-type: y proximity; }

#app > header { position: -webkit-sticky; position: sticky; top: 0px; z-index: 3; padding: 0; background-color: #292e36; }
#app > header > nav.menu { font-family: 'Yanone Kaffeesatz', sans-serif; letter-spacing: 0.5px; text-transform: uppercase; }
#app > header > nav.menu > .item { padding: 2px 0.25em 0; font-size: larger; line-height: 2.5; color: white; }

#app > footer { position: -webkit-sticky; position: sticky; bottom: 0px; z-index: 2; padding: 0; 
  background-color: #F3F4F5; 
  border-radius: 0; color: 'dimgrey';
}

#app > footer > p { color: 'grey'; }

#app > main { flex: 1 1 auto; }

.mac-logo { text-transform: uppercase !important; white-space: pre; color: white !important; opacity: 0.75; }

#age-input::-webkit-input-placeholder {
  color: #FAF9F6;
}

#age-input:-ms-input-placeholder {
  color: #FAF9F6;
}

#age-input::placeholder {
  color: #FAF9F6;
}

/* TODO: see if these are being used. combine if so (low priority)  */
#age-input {
  /* background-color: #E0E1E2; */
  background-color: black;
  font-weight: bold;
  border: 2.75px dimgrey solid;
  font-size: 1.05em;
  color: white;
}

#location-input {
  background-color: black;
  font-weight: bold;
  border: 2.75px dimgrey solid;
  font-size: 1.05em;
  color: white;
}

#population-input {
  background-color: black;
  font-weight: bold;
  border: 2.75px dimgrey solid;
  font-size: 1.05em;
  color: white;
}

#keyword-input {
  background-color: black;
  font-weight: bold;
  border: 2.75px dimgrey solid;
  font-size: 1.05em;
  color: white;
  display: flex;
  flex-direction: row;
  z-index: 2;
}

#keyword-input #keyword-icon {
  justify-self: flex-start;
  margin-right: 15px;
}

#keyword-input i.dropdown.icon.clear::before {
  font-size: 1.25em;
}

#keyword-input input {
  color: white;
}

/* Semantic Grey: F3F4F5 */
#map-nav { scroll-snap-align: start; }
#map-nav > form > .labels { padding: 0; margin: 0; }
#map-nav > form > .labels > .label { text-transform: capitalize; }

.leaflet-container { height: 68vh; }

#map-page { display: grid; grid: 'cards leaflet-container' / 1fr 1fr; grid-gap: 1em; gap: 1em; }
#map-page > .cards { grid-area: cards; width: auto; margin-top: 1rem; }
#map-page > .cards > .card:first-child { margin-top: 0; }
#map-page > .cards > .card { scroll-snap-align: start; }
#map-page > .leaflet-container { grid-area: leaflet-container; width: auto; position: -webkit-sticky !important; position: sticky !important; top: 72px; z-index: 1; }
#map-page .leaflet-popup-content .description { display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden; }

#map-page nav.filters { position: -webkit-sticky; position: sticky; top: 58px; z-index: 2; }
#map-page > nav > .ui.form .field { margin-bottom: 0; }
#map-page .breadcrumb .label > strong { text-transform: capitalize; }

.map-card .segment:empty { display: none; }
.map-card .value-list > dt + dd::before { content: ": "; }
.map-card .value-list > dd + dd::before { content: ", "; }
.map-card .value-list > dt::after, .map-card .value-list > dd::after { content: none !important; }
.map-card .value-list > dt { font-weight: bold; }
.map-card .value-list > dd { display: contents !important; margin: 0 !important; }

@media only screen and (max-width:991px){
  .fixed.sticky { position: absolute !important; }
}
@media only screen and (max-width: 767px) {
  #app > header > nav.menu { min-height: 0; }
  #app > header > nav.menu > .item { font-size: medium; line-height: 2.5; }
  #app > header > nav.menu > .item.mac-logo { display: none; }
  #map-page { grid: 'leaflet-container' 'cards'; }
  #map-page > div.leaflet-container { position: relative !important; top: 0px !important; z-index: 0; }
}
#suggest-update-page { display: flex; flex-flow: column nowrap; }
#suggest-update-page .embed { flex: 1 1 100%; margin: 1rem 0; display: flex; flex-flow: column nowrap; }
#suggest-update-page .tab { flex: 1 1 100%; display: flex; padding: 0; }
#suggest-update-page iframe { flex: 1 0 100%; }
#about-page, #about-page > article { flex: 1 0; display: flex; flex-flow: column; }
#about-page > article > figure { margin: auto 0 0; align-self: flex-end; }
#about-page > article > figure > figcaption { margin: 1rem 0; }

#about-page .embed { flex: 1 1 100%; margin: 1rem 0; display: flex; flex-flow: column nowrap; }

